export const redirects = [
  { from: '/workspaces', to: '/projects' },
  { from: '/team', to: '/settings/project/settings' },
  { from: '/api', to: '/settings/project/secrets' },
  { from: '/cli/events', to: '/events/cli' },
  { from: '/cli/events/*', to: '/events/*' },
  { from: '/cli/requests', to: '/requests/cli' },
  { from: '/cli/requests/*', to: '/requests/*' },
  { from: '/account', to: '/settings/account/profile', exact: true },
  { from: '/settings/account', to: '/settings/account/profile', exact: true },
  { from: '/settings/workspace', to: '/settings/project/general', exact: true },
  { from: '/settings/workspace/settings', to: '/settings/project/general', exact: true },
  { from: '/settings/workspace/members', to: '/settings/organization/members', exact: true },
  { from: '/settings/workspace/billing', to: '/settings/organization/billing', exact: true },
  { from: '/settings/workspace/plans', to: '/settings/organization/plans', exact: true },
  { from: '/settings/workspace/plans/*', to: '/settings/organization/plans/*', exact: true },
  { from: '/workspace/billing/plans', to: '/settings/organization/plans' },
  { from: '/workspace/billing/plans/cancel', to: '/settings/organization/plans/downgrade' },
  { from: '/workspace/invoices', to: '/settings/organization/billing/invoices' },
  { from: '/workspace/*', to: '/settings/project/*' },
  { from: '/settings/workspace/settings/integrations/*', to: '/settings/project/integrations/*' },
  { from: '/settings/workspace/*', to: '/settings/project/*' },
];
