import { useContext } from 'react';
import useSWR from 'swr';

import { Bookmark } from '../../../../../../../typings/Bookmark.interface';
import { Webhook } from '../../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { useCopyToClipboard } from '../../../../utils/copy';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import LabelButton from '../../../common/base/LabelButton';
import Loading from '../../../common/base/Loading';
import Tabs from '../../../common/base/Tabs';
import Text from '../../../common/base/Text';
import { useDialog } from '../../../common/Dialog';
import SelectResourceInput from '../../../common/Form/Fields/SelectResourceInput';
import TextInput from '../../../common/Form/Fields/TextInput';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import ConfirmDeleteDialog from '../Connections/ConfirmDeleteDialog';
import {
  StyledViewScrollable,
  StyledViewSidebar,
  StyledViewSidebarFooter,
  StyledViewSidebarNav,
  StyledViewSidebarSection,
} from '../StyledView';
import FullRequestData from '../../../common/Request/FullRequestData';

const BookmarkDetails: React.FC<{
  bookmark_id: string;
  onUpdate: (bookmark: Bookmark) => void;
  onReplay: (bookmark: Bookmark) => void;
  onDelete: (id: String) => void;
  onClose: () => void;
}> = ({ bookmark_id, onClose, onUpdate, onDelete, onReplay }) => {
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const copyToClipboard = useCopyToClipboard();
  const [active_tab, setActiveTab] = useLocalStorage<'request' | 'details'>(
    'pref:bookmark-sidebar:active-tab',
    'request',
  );
  const { HookdeckAPI } = useContext(GlobalContext);
  const { data: bookmark, mutate } = useSWR(APIMethodKeys.bookmarks.get(bookmark_id), () =>
    HookdeckAPI.bookmarks.get(bookmark_id),
  );
  const { data: webhook } = useSWR(
    bookmark && APIMethodKeys.webhooks.get(bookmark.webhook_id),
    () => HookdeckAPI.webhooks.get(bookmark!.webhook_id),
  );

  const showEdit = () => {
    showDialog(
      (values: { label: string; webhook: Webhook }) => {
        return HookdeckAPI.bookmarks
          .update(bookmark_id, {
            label: values.label,
            webhook_id: values.webhook.id,
          })
          .then((updated_bookmark) => {
            mutate(updated_bookmark);
            onUpdate(updated_bookmark);
            addToast('success', 'Bookmark updated');
          })
          .catch(() => {
            addToast('error', 'Bookmark failed to update');
          });
      },
      () => null,
      {
        title: 'Edit Bookmark',
        submit_label: 'Save',
        cancel_label: 'Cancel',
        form_props: {
          initial_values: { label: bookmark!.label, webhook },
          validate: ({ label }: { label: string }): Partial<{ label: string }> | {} => {
            if (!label || label.length < 0) return { label: 'Required' };
            return {};
          },
          Fields: () => (
            <>
              <TextInput label="Bookmark Label" name="label" required />
              <Text subtitle size="s" as="p" m={{ b: 1 }}>
                Connection
              </Text>
              <SelectResourceInput<Webhook>
                name="webhook"
                getLabel={(r) => r.full_name}
                listResources={HookdeckAPI.webhooks.list}
                listResourcesKey={APIMethodKeys.webhooks.list}
                ResourcePreview={({ resource }) => (
                  <StyledCard p={3}>
                    <Text>{resource.full_name}</Text>
                  </StyledCard>
                )}
              />
            </>
          ),
        },
      },
    );
  };

  const handleDelete = () => {
    if (!bookmark) return;
    showDialog(
      () => {
        HookdeckAPI.bookmarks
          .delete(bookmark.id)
          .then(({ id }) => {
            onClose();
            onDelete(id);
            addToast('success', 'Bookmark deleted');
          })
          .catch(() => addToast('error', 'Failed to remove bookmark'));
      },
      () => null,
      {
        title: 'Delete Bookmark',
        submit_label: 'Delete Bookmark',
        submit_icon: 'delete',
        cancel_label: 'Keep Bookmark',
        danger: true,
        message: ConfirmDeleteDialog({
          text: `By deleting this bookmark, the associated request data will no longer be exempted from your archival window.`,
        }),
      },
    );
  };
  return (
    <StyledViewSidebar>
      <StyledViewSidebarNav background_color="background">
        <Text subtitle>Bookmark</Text>
        <Div flex={{ gap: 2 }}>
          <Button.Permission
            role="member"
            minimal
            icon="replay"
            title={
              webhook?.destination?.type === 'CLI' || !!webhook?.destination?.cli_path?.length
                ? 'Replay on CLI'
                : 'Replay on HTTP'
            }
            onClick={() => bookmark && onReplay(bookmark)}
          />
          <Button.Permission role="member" minimal icon="edit" onClick={() => showEdit()} />
          <Button.Permission role="member" minimal icon="delete" onClick={handleDelete} />
          <Button minimal icon="close" onClick={onClose} />
        </Div>
      </StyledViewSidebarNav>
      <StyledViewScrollable>
        <Div p={{ t: 2, x: 3 }}>
          <Tabs
            compact
            active_tab={active_tab}
            border={false}
            onTabSelected={(tab) => setActiveTab(tab as 'request' | 'details')}
            tabs={[
              { key: 'request', label: 'Request' },
              { key: 'details', label: 'Details' },
            ]}
          />
        </Div>
        <Divider />
        {active_tab === 'request' && (
          <Loading
            require={[bookmark, bookmark?.data]}
            wrapper={(loading) => <StyledViewSidebarSection>{loading}</StyledViewSidebarSection>}>
            {() => (
              <StyledViewSidebarSection>
                <FullRequestData data={bookmark!.data!} compact type="bookmark" id={bookmark!.id} />
              </StyledViewSidebarSection>
            )}
          </Loading>
        )}
        {active_tab === 'details' && (
          <Loading
            require={[bookmark, webhook]}
            wrapper={(loading) => <StyledViewSidebarSection>{loading}</StyledViewSidebarSection>}>
            {() => (
              <StyledViewSidebarSection>
                <StyledCard m={{ b: 4 }}>
                  <StyledCardSection p={{ x: 4, y: 3 }}>
                    <Text size="s" subtitle m={{ b: 1 }}>
                      Bookmark Label
                    </Text>
                    {bookmark?.label}
                  </StyledCardSection>
                  <StyledCardSection p={{ x: 4, y: 3 }}>
                    <Text size="s" subtitle m={{ b: 1 }}>
                      Connection
                    </Text>
                    <LabelButton
                      neutral
                      monospace
                      label={webhook!.full_name}
                      to={`/connections/${bookmark!.webhook_id}`}></LabelButton>
                  </StyledCardSection>
                </StyledCard>
              </StyledViewSidebarSection>
            )}
          </Loading>
        )}
      </StyledViewScrollable>
      <StyledViewSidebarFooter>
        <ClickableArea
          rounded
          block={false}
          p={{ x: 2, y: 1 }}
          flex={{ align: 'center' }}
          onClick={() => copyToClipboard(bookmark?.event_data_id || '')}>
          <Text muted monospace>
            {bookmark?.event_data_id}
          </Text>
          <Icon right icon="copy" muted />
        </ClickableArea>
      </StyledViewSidebarFooter>
    </StyledViewSidebar>
  );
};

export default BookmarkDetails;
