import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import { Bookmark } from '../../../../../../../typings/Bookmark.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import {
  BookmarksFiltersProps,
  BookmarksQueryParams,
} from '../../../../typings/Bookmarks.interface';
import { extractFromArray } from '../../../../utils';
import Button from '../../../common/base/Button';
import EmptyState from '../../../common/EmptyState';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import EventPreview from '../Events/EventPreview';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import BookmarkDetails from './BookmarkDetails';
import BookmarksList from './BookmarksList';
import { UserContext } from '../../../contexts/UserContext';
import { useToasts } from '../../../common/Toast';

const extractFiltersFromQuery = (parsed_query: BookmarksQueryParams): BookmarksFiltersProps => {
  return {
    next: extractFromArray(parsed_query.next || []) as string,
    prev: extractFromArray(parsed_query.next || []) as string,
  };
};

const BookmarksView: React.FC = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const { has_connection } = useContext(DashboardContext);
  const theme = useTheme();
  const { addToast } = useToasts();
  const { query, updateSearchQuery } = useSearchQuery<BookmarksQueryParams>();
  const selected_event_id = extractFromArray(query.selected_event_id || '') as string;
  const selected_id = extractFromArray(query.selected_id || '') as string;
  const filters = extractFiltersFromQuery(query);
  const [event_list_render_key, setRenderKey] = useState(Date.now());

  const onEntrySelected = (selected_id: string) =>
    updateSearchQuery({ selected_id }, { remove_keys: ['selected_event_id'] });
  const onEventSelected = (selected_event_id: string) => updateSearchQuery({ selected_event_id });
  const onFilterChanged = (new_filters: Partial<BookmarksQueryParams>) => {
    const should_update = Object.entries(new_filters).some(
      ([key, value]) => JSON.stringify(filters[key]) !== JSON.stringify(value),
    );
    if (should_update) {
      updateSearchQuery(new_filters, { remove_keys: ['selected_id', 'selected_event_id'] });
    }
  };

  const { data, mutate } = useSWR(APIMethodKeys.bookmarks.list(filters), () =>
    HookdeckAPI.bookmarks.list(filters),
  );

  const entry =
    selected_id && data?.models ? data.models.find((event) => event.id === selected_id) : null;

  const { data: events, mutate: mutateEvents } = useSWR(
    entry &&
      APIMethodKeys.events.list({
        event_data_id: entry.event_data_id,
        limit: 10,
      }),
    () =>
      HookdeckAPI.events.list({
        event_data_id: entry!.event_data_id,
        limit: 10,
      }),
    {
      refreshInterval: 10000,
      revalidateOnFocus: true,
    },
  );

  const onReplay = (bookmark: Bookmark) => {
    HookdeckAPI.bookmarks
      .trigger(bookmark.id)
      .then((new_events) => {
        if (new_events.length === 0) {
          addToast(
            'error',
            'No events created, is your connection filters excluding your bookmarked data?',
          );
        } else {
          if (events) {
            mutateEvents(
              {
                ...events,
                models: [...new_events, ...events.models],
              },
              false,
            );
          }
          mutate((data) => {
            if (data) {
              const index = data.models.findIndex((m) => m.id === bookmark.id);
              data.models[index] = { ...bookmark, last_used_at: new_events[0].created_at };
              return data;
            }
          });
          addToast('success', 'Bookmark replayed');
        }
      })
      .catch(() => {
        addToast('error', 'Bookmark failed to replay');
      });
  };

  useEffect(() => {
    mutate();
  }, [event_list_render_key]);

  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav breadcrumb={[{ icon: 'bookmarks', title: 'Bookmarks' }]}>
          <Button
            icon="refresh"
            outline
            onClick={() => {
              updateSearchQuery({}, { remove_keys: ['prev', 'next', 'selected_event_id', 'id'] });
              setRenderKey(Date.now());
            }}>
            Refresh
          </Button>
        </PageNav>
        {has_connection ? (
          <BookmarksList
            key={event_list_render_key}
            bookmarks={data}
            onEventSelected={onEventSelected}
            onBookmarkSelected={onEntrySelected}
            selected_id={selected_id}
            selected_event_id={selected_event_id}
            onFilterChanged={onFilterChanged}
            onReplay={onReplay}
            events={events}
          />
        ) : (
          <EmptyState
            title="Organize important events"
            description="Bookmarks allow you to conveniently store, document, and replay specific events. Create a connection to manage and bookmark requests."
            asset={`/images/empty/bookmarks-${theme.mode}.svg`}
            cta={{
              label: 'Create connection',
              icon: 'add_circle',
              to: '/create-first-connection',
            }}
          />
        )}
      </StyledViewContent>
      {selected_id && !selected_event_id && (
        <BookmarkDetails
          bookmark_id={selected_id}
          onUpdate={(bookmark: Bookmark) => {
            mutate((data) => {
              const index = data!.models.findIndex((m) => m.id === bookmark.id);
              data!.models[index] = bookmark;
              return data;
            });
          }}
          onReplay={onReplay}
          onDelete={(id: string) => {
            mutate((data) => {
              const models = data!.models.filter((m) => m.id === id);
              return {
                ...data,
                count: models.length,
                pagination: {
                  ...data,
                  next: models[models.length - 1].id,
                  prev: models[0].id,
                },
                models,
              };
            });
          }}
          onClose={() =>
            updateSearchQuery({}, { remove_keys: ['selected_event_id', 'selected_id'] })
          }
        />
      )}
      {selected_event_id && (
        <EventPreview
          event_id={selected_event_id}
          onClose={() =>
            updateSearchQuery({}, { remove_keys: ['selected_event_id', 'selected_id'] })
          }
        />
      )}
    </StyledViewWrapper>
  );
};

export default BookmarksView;
