import styled, { css } from 'styled-components';

import { Div } from '../common/helpers/StyledUtils';
import { useIsDegradedBanner } from '../hooks/useIsDegradedBanner';
import Tooltip from '../common/base/Tooltip';
import Icon from '../common/base/Icon';
import Text from '../common/base/Text';

export const StyledViewLayout = styled.div`
  display: flex;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.surface.base.surface};
`;

export const StyledViewNav = styled.div(({ theme }) => {
  const degraded_banner_active = useIsDegradedBanner();

  return css`
    position: sticky;
    top: ${degraded_banner_active ? '32px' : 0};
    flex-shrink: 0;
    background-color: ${theme.colors.surface.base.surface};
    z-index: ${theme.zindex.sticky};
  `;
});

export const StyledViewNavHeader = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(5)} ${theme.spacing(8)} ${theme.spacing(2)} ${theme.spacing(8)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 60px;
    box-sizing: border-box;
  `,
);

export const StyledViewNavSecondary = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(2)} ${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(8)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    box-sizing: border-box;
    border-bottom: ${theme.border};
  `,
);

export const StyledViewHeader = styled.div(
  ({ theme }) => css`
    position: sticky;
    padding: ${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(6)} ${theme.spacing(8)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 60px;
  `,
);

const StyledViewTitle = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
);

export const ViewTitle = ({
  title,
  tooltip,
  link,
}: {
  title: string;
  tooltip?: string;
  link?: string;
}) => {
  return (
    <StyledViewTitle>
      <Text subtitle size="l">
        {title}
      </Text>
      {tooltip && link && (
        <Tooltip tooltip={tooltip} cta={{ icon: 'docs', label: 'View Docs', to: link }}>
          <Icon icon="info" />
        </Tooltip>
      )}
    </StyledViewTitle>
  );
};

export const ViewSidebarTitle = ({ title }: { title: string }) => {
  return (
    <StyledViewTitle>
      <Text subtitle size="m">
        {title}
      </Text>
    </StyledViewTitle>
  );
};

export const StyledViewActions = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacing(2)};
  `,
);

export const StyledViewContent = styled.div<{ light?: boolean }>(
  ({ light, theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    min-width: 0;
    min-height: 100%;
    ${light &&
    css`
      background-color: ${theme.colors.surface.base.surface};
    `}
  `,
);

export const StyledViewSidebar = styled.div<{ width?: 'small' | 'large' }>(({ theme, width }) => {
  const computed_width = width === 'large' ? '40vw' : theme.pxToRem(480);
  return css`
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 0;
    flex-basis: ${computed_width};
    width: ${computed_width};
    min-width: ${computed_width};
    background-color: ${theme.colors.surface.base.surface};
    border-left: ${theme.border};
    height: 100vh;
    z-index: ${theme.zindex.sticky};
  `;
});

export const StyledViewSidebarSection = styled(Div)(
  ({ theme }) => css`
    padding: ${theme.spacing(4)};
    :not(:last-child) {
      border-bottom: ${theme.border};
    }
  `,
);

export const StyledViewSidebarNav = styled(StyledViewNav)`
  ${StyledViewNavHeader} {
    padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(6)}
      ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(6)};
  }
`;

export const StyledViewScrollable = styled.div`
  flex-grow: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
