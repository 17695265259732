import { useContext, useMemo } from 'react';
import { OrderByDirection } from '../../../../../typings/API.interface';
import { Request } from '../../../../../typings/Request.interface';
import APIMethodKeys from '../../client/APIMethodKeys';
import { RequestListFiltersProps, RequestListOrderBy } from '../../typings/RequestList.interface';
import { GlobalContext } from '../contexts/GlobalContext';
import useRefreshingList, { RefreshListOptions, RefreshingListResult } from './useRefreshingList';

export const formatRequestFiltersForAPI = (
  filters: RequestListFiltersProps,
  order_by: RequestListOrderBy,
) => {
  const formatted_filters: { [k: string]: any } = {};
  if (typeof filters.date === 'object' && (filters.date.min || filters.date.max)) {
    formatted_filters[order_by] = {
      gte: filters.date.min,
      lte: filters.date.max,
    };
  }

  if (filters.ignored_count?.min || filters.ignored_count?.max) {
    formatted_filters.ignored_count = {
      ...(filters.ignored_count.min ? { gte: parseInt(filters.ignored_count.min) } : {}),
      ...(filters.ignored_count.max ? { lte: parseInt(filters.ignored_count.max) } : {}),
    };
  }

  if (filters.events_count?.min || filters.events_count?.max) {
    formatted_filters.events_count = {
      ...(filters.events_count.min ? { gte: parseInt(filters.events_count.min) } : {}),
      ...(filters.events_count.max ? { lte: parseInt(filters.events_count.max) } : {}),
    };
  }

  if (filters.cli_events_count?.min || filters.cli_events_count?.max) {
    formatted_filters.cli_events_count = {
      ...(filters.cli_events_count.min ? { gte: parseInt(filters.cli_events_count.min) } : {}),
      ...(filters.cli_events_count.max ? { lte: parseInt(filters.cli_events_count.max) } : {}),
    };
  }

  formatted_filters.next = filters.next;
  formatted_filters.prev = filters.prev;

  formatted_filters.search_term = filters?.search_term ?? undefined;
  formatted_filters.status = filters?.status ?? undefined;
  formatted_filters.headers = filters.request?.headers ?? undefined;
  formatted_filters.body = filters.request?.body ?? undefined;
  formatted_filters.path = filters.request?.path ?? undefined;
  formatted_filters.parsed_query = filters.request?.parsed_query ?? undefined;

  formatted_filters.source_id =
    !filters.source_id || filters.source_id.length === 0 ? undefined : filters.source_id;

  formatted_filters.bulk_retry_id =
    !filters.bulk_retry_id || filters.bulk_retry_id.length === 0
      ? undefined
      : filters.bulk_retry_id;

  if (filters.rejection_cause) {
    formatted_filters.rejection_cause =
      !filters.rejection_cause || filters.rejection_cause.length === 0
        ? undefined
        : filters.rejection_cause;
  }

  return formatted_filters;
};

export interface RequestListResult extends Omit<RefreshingListResult<Request>, 'models'> {
  requests: Request[];
}

const useRequestList = (
  filters: RequestListFiltersProps,
  order_by: RequestListOrderBy,
  dir: OrderByDirection,
  options?: RefreshListOptions,
): RequestListResult => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const formatted_filters = useMemo(
    () => formatRequestFiltersForAPI({ ...filters }, order_by as any),
    [filters, order_by],
  );
  const props = useRefreshingList<Request>(
    APIMethodKeys.requests.list,
    HookdeckAPI.requests.list,
    formatted_filters,
    order_by,
    dir,
    options,
  );

  return {
    requests: props.models,
    ...props,
  };
};

export default useRequestList;
